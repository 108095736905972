import './App.css';
import Navbar from './Components/Navbar';
import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import Home from './Views/Home';
import Footer from './Components/Footer';
import Contact from './Views/Contact';
import Gallery from './Views/Gallery';
import Serivces from './Views/Services';
import {Fade , Slide , } from "react-awesome-reveal";
import FloatBtn from './Components/FloatBtn';

function App() {
  return (
    <Fade delay={100}>
      <main className=' font-Catamaran'>
        <Navbar />
        <Routes>
          <Route path = '/' element = {<Home />} />
          <Route path = '/Contact' element = {<Contact />} /> 
          <Route path = '/Gallery' element = {<Gallery /> } />
          <Route path = "/Services" element = {<Serivces /> } />
        </Routes>
        <FloatBtn />
        <Footer />
      </main>
    </Fade>
  );
}

export default App;
