const ServicesData = [
    {
        id: 1,
        servicename: 'Manicure & Pedicure',
        service: [
            {
                id: 1,
                name: 'Manicure',
                price: '$35'
            },
            {
                id: 2,
                name: 'Pedicure',
                price: '$40-50'
            },
            {
                id: 3,
                name: 'Mani & Pedi',
                price: '$85'
            },
            {
                id: 4,
                name: 'French Manicure',
                price: '$45'
            },
            {
                id: 5,
                name: 'Pink & White',
                price: '$55'
            }
        ]
    },

    {
        id: 2,
        servicename: 'Custom Design',
        service: [
            {
                id: 1,
                name: 'Nail Design',
                price: '$15'
            },
            {
                id: 2,
                name: 'Nail Repair',
                price: '$5'
            },
            {
                id: 3,
                name: 'Nail Take Off',
                price: '$15'
            },
            {
                id: 4,
                name: 'Air Brush Design',
                price: ''
            },
            {
                id: 5,
                name: 'Polish Change',
                price: '$25'
            },
            {
                id: 6,
                name: 'Micro Blading',
                price: ''
            }
        ]
    },
    
    {
        id: 3,
        servicename: 'Facial & Waxing',
        service: [
            {
                name: 'Facial',
                price: '$40'
            },
            {
                name: 'Eyebrow',
                price: '$12'
            },
            {
                name: 'Lip',
                price: '$8'
            },
            {
                name: 'Chin',
                price: '$10'
            },
            {
                name: 'Full Chin',
                price: '$15'
            },
            {
                name: 'Full Face',
                price: '$40'
            },
            {
                name: 'Stomach',
                price: '$25'
            },
            {
                name: 'Chest',
                price: '$35+'
            },
            {
                name: 'Full Leg',
                price: '$85'
            },
            {
                name: 'Full Arm',
                price: '$50+'
            },
            {
                name: 'Under Arm',
                price: '$15'
            },
            {
                name: 'Bikini',
                price: '$40+'
            },
            {
                name: 'Back',
                price: '$50'
            },
            {
                name: 'Eye Brown',
                price: '$12'
            }
        ]
    }
];

export default ServicesData;