import React from "react";

function Button(props){
    const ChangeUrl = (url) => {
        window.location.href = (`${url}`);
    };

    return (
        <button onClick={() => ChangeUrl(props.url)} className="hover:shadow-lg hover:-translate-y-2 hover:bg-main-color hover:text-white ease-in-out delay-100 duration-150 md:text-xl text-black border-2 border-main-color text-lg py-3 group cursor-pointer w-[250px] md:tracking-wide">
            {props.name}
        </button>
    );
};

export default Button;