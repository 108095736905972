import React from "react";
export default function Contact(){

    return (
        <section className = "space-y-8">
            <div className="bg-gpage3 bg-no-repeat lg:bg-center bg-cover h-[300px] lg:h-[600px] relative">
                <div className=" bg-black w-full h-full bg-opacity-60"></div>
                <div className="font-newFont top-8 lg:top-12 w-full absolute text-3xl h-full flex items-center justify-center cursor-pointer text-black lg:text-5xl">
                    <h2 className=" text-white">Contact Us</h2>
                </div>
            </div>
            <div className="mx-auto lg:max-w-screen-xl cursor-pointer">
                <div className="grid grid-cols-1 text-justify lg:gap-8 lg:grid-cols-2">
                    <div className="grid justify-items-center">
                        <iframe className="w-full h-[600px]" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3102.5226226589775!2d-122.6482659!3d38.957732899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80838b7dcc7cef5d%3A0x562573cac6d1f65f!2sFinest%20Nails!5e0!3m2!1sen!2sus!4v1710122630472!5m2!1sen!2sus" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="p-4 space-y-4 lg:space-y-8 lg:text-justify lg:grid justify-center text-center">
                        <div className="">
                            <div className="grid grid-cols-1 p-4 space-y-5">
                                <div>
                                    <span className="text-3xl tracking-wider text-main-color">See us in person</span>
                                </div>
                                <div className="space-y-3">
                                   <p className="font-newFont">We love our customers and welcome them to visit during our normal business hours of 8:00 am to 6:00 pm, Monday through Saturday.</p>
                                   <ul className="space-y-2 font-semibold">
                                        <li>
                                            <a className="transition text-black t-underline t-underline-black border-b-yellow-500" href="tel:6692076966">
                                                Phone#: (669) 207-6966
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="tel:7079951737">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 shrink-0 text-gray-900"
                                                    fill="#e15668"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                                    />
                                                </svg>
                                                <span className="flex-1 text-gray-700">(707) 995-1737</span>
                                            </a>
                                        </li> */}
                                   </ul>
                                </div>
                                <hr></hr>
                                <div>
                                    <div className="font-semibold text-lg">Business Hours:</div>
                                    <ul className="space-y-4 text-sm mt-4">
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Mon: 8:00 am - 6:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Tues:  8:00 am - 6:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Wed:  8:00 am - 6:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Thu:  8:00 am - 6:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Fri:  8:00 am - 6:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Sat: 8:00 am - 6:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span href="" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Sun: Closed
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}