import {React} from "react";
import Wave from "../img/wave.png";
import AboutImg from "../img/test.png";
import Button from "../Components/Button";
import nail from "../img/nail.jpg";
import eyelash from "../img/eyelash.jpg";
import custom from "../img/custom.jpg";
import manicure from "../img/manicure.png";
import pedicure from "../img/pedicure.png";
import eyelash2 from "../img/eyelash.png";
import facial from "../img/facial.png";
import Popup from "../Components/Popup";

function importImg(r){
    return r.keys().map(r);
}

export default function Home(){
	const fnames = importImg(require.context('../img/nail-img/' , false , /\.(png|jpg|jpeg)$/));
    return(
        <>
            <Popup />
            <section className="relative font-Lora overflow-y-hidden overflow-x-hidden">
                <div className="w-screen bg-pagesm lg:bg-bg1 bg-no-repeat bg-center bg-cover h-screen relative">
                    <div className="w-screen absolute lg:right-64 text-center top-1/3 px-3 py-5 space-y-5 lg:py-8 md:space-y-8">
                        <div className="">
                            <h1 className="text-5xl font-mono capitalize ml-4 italic text-white md:text-7xl tracking-widest font-bold">
                               Finest Nail
                            </h1>
                        </div>
                        <div className="p-2">
                            <div className="italic">
                                <ul className=" text-sky-200 space-y-2 font-light font-newFont">
                                    <li>
                                        <h2 className="text-xl md:text-3xl tracking-wide">Make your manicure dreams real</h2>
                                    </li>
                                    <li>
                                        <h2 className="text-xl md:text-2xl tracking-wide italic">Experience pampering</h2>
                                    </li>
                                    <li>
                                        <h2 className="text-xl md:text-2xl tracking-wide"> at Finest Nails Clearlake</h2>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button className="hover:bg-white/0 hover:border-[1px] rounded-full hover:border-white transition duration-200 delay-100 md:text-2xl text-white text-lg py-3 group cursor-pointer px-12 bg-main-color md:tracking-wide">
                            <a target="_blank" href="tel:6692076966" className = "group-hover:text-white transition duration-200 delay-100 font-extrabold">Book Appointment</a>
                        </button>
                    </div>
                </div>
                <div className="mx-auto mb-6">
                    <div className=" grid gap-4 lg:grid-cols-2">
                        <div className="">
                            <img className="" src = {AboutImg} />
                        </div>
                        <div className=" flex flex-col lg:justify-center space-y-4 lg:space-y-8">
                            <div className=" mx-5 text-3xl flex lg:items-start items-center justify-center flex-col lg:text-5xl lg:space-y-4 text-main-color font-Roboto tracking-wider">
                                <h2>Who we are?</h2>
                                <div className=" border-[1px] w-44 border-main-color"></div>
                            </div>
                            <div className="space-y-3 px-6 font-Poppins font-extrabold">
                                <div className="lg:text-2xl text-xl tracking-wide text-black italic">
                                    <span className="">
                                        Welcome to Finist Nail Salon, your premier destination for luxurious nail care in the heart of Clearlake, California. 
                                        Nestled in the scenic beauty of Lake County, our salon is dedicated to providing you with an unparalleled nail care experience, 
                                        where beauty, comfort, and personalized attention combine to create a memorable visit.
                                    </span>
                                </div>
                                <ul className="text-lg lg:w-4/5 text-black/60 space-y-2 list-inside list-disc">
                                    <li className="">
                                        Our team consists of talented professionals who stay abreast of the latest nail care 
                                        techniques and trends to offer you the best service possible
                                    </li>
                                    <li>
                                        Our team of highly skilled and experienced nail 
                                        technicians are passionate about beauty and take pride in delivering exceptional service and precision workmanship
                                    </li>
                                </ul>
                            </div>
                            <div className=" lg:mx-12 flex lg:justify-start justify-center">
                                <Button url = 'tel:6692076966' name = "Book Now" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" bg-[#eaf2f5] mx-auto lg:py-32 px-4 py-8">
                    <div className=" flex flex-col space-y-4 lg:space-y-12">
                        <div className="flex flex-col items-center justify-center text-center text-3xl lg:text-5xl lg:space-y-4 text-main-color font-Roboto">
                            <h2>What we Do?</h2>
                            <div className=" border-[1px] w-44 border-main-color"></div>
                        </div>
                        <div className="lg:text-lg text-sm flex justify-center text-center">
                            <div className="lg:w-1/2">
                                <span className="text-black/60">
                                    Discover top-notch beauty solutions at unbeatable prices. 
                                    Elevate your beauty while keeping your finances in check with our exceptional offers.
                                </span>
                            </div>
                        </div>
                        <div className="lg:p-4 p-2 mx-auto max-w-screen-xl">
                            <div className=" grid gap-12 lg:gap-24 place-items-center lg:grid-cols-3">
                            <div className=" group cursor-pointer hover:shadow-lg hover:bg-main-color p-4 flex text-center justify-center items-center space-y-5 flex-col">
                                    <img className="w-32 md:invert-0 md:group-hover:invert" src={custom} alt="#" />
                                    <div className=" flex flex-col space-y-5">
                                        <div className=" space-y-2">
                                            <div>
                                                <h2 className=" text-2xl group-hover:text-white ">Manicure</h2>
                                            </div>
                                            <div>
                                                <span className="group-hover:text-white">
                                                    Elevate your hand aesthetics with our manicure services, where elegance meets expression. From sleek, minimalist looks to vibrant, artistic creations, we tailor every manicure to your unique taste, ensuring your nails not only look stunning but also speak volumes about your personal style.                                                
                                                </span>
                                            </div>
                                        </div>
                                        <div className="">
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = 'tel:6692076966';
                                            }} className="group-hover:shadow-lg hover:-translate-y-2 group-hover:bg-white group-hover:text-black ease-in-out delay-100 duration-150 md:text-xl text-black border-2 border-main-color text-lg py-3 group cursor-pointer w-[250px] md:tracking-wide">
                                                Book Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className=" group cursor-pointer hover:shadow-lg hover:bg-main-color p-4 flex text-center justify-center items-center space-y-5 flex-col">
                                    <img className="w-32 md:invert-0 md:group-hover:invert" src={nail} alt="#" />
                                    <div className=" flex flex-col space-y-5">
                                        <div className=" space-y-2">
                                            <div>
                                                <h2 className=" text-2xl group-hover:text-white ">Pedicure</h2>
                                            </div>
                                            <div>
                                                <span className="group-hover:text-white">
                                                    Step into a world of comfort and style with our pedicure services. We focus on both health and beauty, offering a soothing experience that rejuvenates your feet and adorns them with exquisite designs. Whether you prefer classic elegance or bold statements, our pedicures are personalized to match your individual flair.                                                
                                                </span>
                                            </div>
                                        </div>
                                        <div className="">
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = 'tel:6692076966';
                                            }} className="group-hover:shadow-lg hover:-translate-y-2 group-hover:bg-white group-hover:text-black ease-in-out delay-100 duration-150 md:text-xl text-black border-2 border-main-color text-lg py-3 group cursor-pointer w-[250px] md:tracking-wide">
                                                Book Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className=" group cursor-pointer hover:shadow-lg hover:bg-main-color p-4 flex text-center justify-center items-center space-y-5 flex-col">
                                    <img className="w-32 md:invert-0 md:group-hover:invert" src={eyelash} alt="#" />
                                    <div className=" flex flex-col space-y-5">
                                        <div className=" space-y-2">
                                            <div>
                                                <h2 className=" text-2xl group-hover:text-white ">Eyelash</h2>
                                            </div>
                                            <div>
                                                <span className="group-hover:text-white">
                                                    Transform your eyes into mesmerizing statements with our eyelash extension services. We specialize in enhancing your natural beauty by adding volume and length to your lashes, tailored to your desired look. From subtle enhancements to dramatic flair, our expert applications ensure you captivate with every glance.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="">
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = 'tel:6692076966';
                                            }} className="group-hover:shadow-lg hover:-translate-y-2 group-hover:bg-white group-hover:text-black ease-in-out delay-100 duration-150 md:text-xl text-black border-2 border-main-color text-lg py-3 group cursor-pointer w-[250px] md:tracking-wide">
                                                Book Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" bg-white mx-auto lg:py-32 px-4 py-8">
                    <div className=" flex flex-col space-y-4 lg:space-y-12">
                        <div className="flex flex-col items-center justify-center text-center text-3xl lg:text-5xl lg:space-y-4 text-main-color font-Roboto">
                            <h2>Services Price</h2>
                            <div className=" border-[1px] w-44 border-main-color"></div>
                        </div>
                        <div className="lg:text-lg text-sm flex justify-center text-center">
                            <div className="lg:w-1/2">
                                <span className="text-black/60">
                                    Unlock premium service experiences without overspending. Enhance your lifestyle and manage your budget smartly 
                                    with our exclusive deals.
                                </span>
                            </div>
                        </div>
                        <div className="lg:p-4 p-2 mx-auto max-w-screen-2xl">
                            <div className=" grid gap-6 place-items-center lg:grid-cols-4">
                                <div className="cursor-pointer group lg:border-2 lg:hover:border-main-color shadow-lg p-4 flex text-center justify-center items-center space-y-5 flex-col">
                                    <img className="" src={manicure} alt="#" />
                                    <div className=" flex flex-col space-y-5">
                                        <div className=" space-y-2">
                                            <div>
                                                <h2 className=" text-2xl italic ">Manicure</h2>
                                            </div>
                                        </div>
                                        <div className="">
                                           <Button url = '/Services' name = "$35" />
                                        </div>
                                    </div>
                                </div>
                                <div className="cursor-pointer  lg:border-2 lg:hover:border-main-color shadow-lg p-4 flex text-center justify-center items-center space-y-5 flex-col">
                                    <img className="" src={pedicure} alt="#" />
                                    <div className=" flex flex-col space-y-5">
                                        <div className=" space-y-2">
                                            <div>
                                                <h2 className="text-2xl italic  ">Pedicure</h2>
                                            </div>
                                        </div>
                                        <div className="">
                                           <Button url = '/Services' name = "$40-$50"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="cursor-pointer lg:border-2 lg:hover:border-main-color shadow-lg p-4 flex text-center justify-center items-center space-y-5 flex-col">
                                    <img className="" src={eyelash2} alt="#" />
                                    <div className=" flex flex-col space-y-5">
                                        <div className=" space-y-2">
                                            <div>
                                                <h2 className=" text-2xl italic">Eyelash</h2>
                                            </div>
                                        </div>
                                        <div className="">
                                           <Button  url = '/Services' name = "View More" />
                                        </div>
                                    </div>
                                </div>
                                <div className="cursor-pointer group lg:border-2 lg:hover:border-main-color shadow-lg p-4 flex text-center justify-center items-center space-y-5 flex-col">
                                    <img className="" src={facial} alt="#" />
                                    <div className=" flex flex-col space-y-5">
                                        <div className=" space-y-2">
                                            <div>
                                                <h2 className=" text-2xl italic">Facial</h2>
                                            </div>
                                        </div>
                                        <div className="">
                                           <Button url = '/Services' name = "$40" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" bg-[#eaf2f5] mx-auto lg:py-32 px-4 py-8">
                    <div className=" flex flex-col space-y-4 lg:space-y-12">
                        <div className="flex flex-col items-center justify-center text-center text-3xl lg:text-5xl lg:space-y-4 text-main-color font-Roboto">
                            <h2>Our Gallery</h2>
                            <div className=" border-[1px] w-44 border-main-color"></div>
                        </div>
                        <div className="lg:text-lg text-sm flex justify-center text-center">
                            <div className="lg:w-1/2">
                                <span className="text-black/60">
                                    Explore exquisite gallery collections at competitive prices. Adorn your spaces while optimizing your budget with 
                                    our unique selections.
                                </span>
                            </div>
                        </div>
                        <div className="lg:p-4 p-2 mx-auto max-w-screen-xl">
                            <div className="grid lg:grid-cols-4 gap-5 md:grid-cols-2 justify-items-center p-4 cursor-pointer">
                                {fnames.slice(0,8).map((fname , index) =>(
                                    <div key={index} className="shadow-lg overflow-hidden rounded-lg">
                                        <img className="w-full ease-in-out transition-all duration-1000 hover:scale-125" src={fname} alt={fname}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className=" flex justify-center">
                            <Button url = '/Gallery' name = "View More" />
                        </div>
                    </div>
                </div>
                <div className="lg:p-4 p-2 bg-cover lg:bg-center lg:h-[518px] bg-no-repeat w-screen bg-gpage2 relative">
                    <div className="px-12 lg:ml-28 py-8 space-y-5 lg:py-24 md:space-y-6">
                        <div className=" flex flex-col space-y-6 lg:space-y-8">
                            <div className=" max-w-lg text-center">
                                <span className=" font-Lora text-xl lg:text-2xl">
                                    We also teach
                                </span>
                            </div>
                            <div className=" max-w-lg flex flex-col space-y-5 text-center">
                                <span delay={100} duration={300} cascade className="font-Lora text-main-color text-4xl font-extrabold lg:text-6xl">
                                    Micro-Bladding
                                </span>
                                <span className="font-Lora text-black/60 text-lg capitalize">
                                    lesson
                                </span>
                            </div>
                            <div className=" flex max-w-lg justify-center">
                                <Button url = 'tel:6692076966' name = "Call Us" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};